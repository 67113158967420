import React from "react"
import { cx } from "emotion"
import Image from "../imageVar"
import { 
  Space, 
  Flex,
  Typo,
  Background
} from "../../styles"
import {
  Mail,
  Phone,
  LinkedIn,
  Task,
  Etc
} from "../icons"

const Tasks = {
  Email: ({ step = null, text = "Email"}) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.red, Flex.hcc, Background.red, Space.p1)} style={{borderRadius:2}}>
          <Mail style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },
  Draft: ({ step = null, text = "Email"}) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.orange, Flex.hcc, Background.orange, Space.p1)} style={{borderRadius:2}}>
          <Mail style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },
  Call: ({ step = null, text = "Call" }) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.green, Flex.hcc, Background.green, Space.p1)} style={{borderRadius:2}}>
          <Phone style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },
  Linkedin: ({ step = null, text = "LinkedIn Task" }) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.blue, Flex.hcc, Background.blue, Space.p1)} style={{borderRadius:2}}>
          <LinkedIn style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },
  Task: ({ step = null, text = "Task" }) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.yellow, Flex.hcc, Background.yellow, Space.p1)} style={{borderRadius:2}}>
          <Task style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },
  More: ({ step = null, text = "Task" }) => {
    return (
      <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)} style={{borderRadius:2}}>
        <div className={cx(Typo.muted, Flex.hcc, Background.grey, Space.p1)} style={{borderRadius:2}}>
          <Etc style={{width:18,height:18}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
          <div className={cx(Typo.small, Typo.muted)}>{text}</div>
        </div>
      </div>
    )
  },

  Outcome: ({ text }) => {
    return (
      <div></div>
    )
  }
}

export default Tasks