import React, {Component} from 'react';

import {XYPlot, XAxis, YAxis, HeatmapSeries, Hint} from 'react-vis';

import { withSize } from "react-sizeme"

class HeatmapChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: props.size.width,
      value: false
    }
  }

  render() {
    const {value} = this.state;
    return (
      <XYPlot width={300} height={300}>
        <XAxis 
          tickValues={[0,1,2,3,4,5,6,7]}
          tickFormat={v => `Competitor ${v}`}
          />
        <YAxis 
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
          tickFormat={v => {
            if (v === 0) return "Mo";
            if (v === 1) return "Tu";
            if (v === 2) return "We";
            if (v === 3) return "Th";
            if (v === 4) return "Fr";
            if (v === 5) return "Sa";
            if (v === 6) return "Su";
          }}
          />
        <HeatmapSeries
          className="heatmap-series-example"
          onValueMouseOver={v => this.setState({value: v})}
          onSeriesMouseOut={v => this.setState({value: false})}
          data={[
            {x: 1, y: 0, color: 10},
            {x: 1, y: 5, color: 10},
            {x: 1, y: 7, color: 6},
            {x: 1, y: 6, color: 7},
            {x: 2, y: 0, color: 12},
            {x: 2, y: 5, color: 2},
            {x: 2, y: 4, color: 1},
            {x: 2, y: 5, color: 12},
            {x: 3, y: 0, color: 9},
            {x: 3, y: 5, color: 2},
            {x: 3, y: 1, color: 6},
            {x: 3, y: 7, color: 12}
          ]}
        />
        {value !== false && <Hint value={value} />}
      </XYPlot>
    );
  }
}

export default withSize()(HeatmapChart)