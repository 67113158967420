import React from 'react'
import { cx } from 'emotion'
import { Space, Background, Typo, Border } from '../styles'
import { Row, Col } from "reactstrap"
import Typos from "../components/Elements/Typo"
import Image from "../components/imageVar"
import { Penta, Octa } from "../components/shapes"


const Element = () => {
  return (
    <div className={cx(Space.section)}>
      <div className={cx(Typo.orange)} style={{position:"absolute",left:-150,bottom:-50,width:300,height:300,transform:"rotate(270deg)"}}><Penta /></div>
      <Typos.SectionTitle 
          text={<span>The new inbound sales stack</span>} />
      {/*<div>
        <p>Where Deeploop gives you the Edge</p>
        The new inbound sales stack
      </div>*/}
      <Row>
        <Col xs="12" sm="4" style={{display:"flex"}}>
          <div className={cx(Border.top4, Border.primary, Space.p5)}>
            <div style={{width:60,marginBottom:20}}>
              <Image filename="icon/r2_control.png" />
            </div>
            <h4 className={cx(Typo.bold, Typo.primary)}>Control</h4>
            <p>
              Segment leads, detect lead activity and automatically assign to workflows. Design and optimize workflows with email, calls, LinkedIn and other tasks.
            </p>
          </div>
        </Col>
        <Col xs="12" sm="4">
          <div className={cx(Border.top4, Border.teal, Space.p5)}>
            <div style={{width:60,marginBottom:20}}>
              <Image filename="icon/r1_execute.png" />
            </div>
            <h4 className={cx(Typo.bold, Typo.primary)}>Execute</h4>
            <p>
              Give your team the tools to efficiently execute inbound. Web dialer, A.I. enhanced email editor, 360 lead analytics and much more, combined into a simple todo list.
            </p>
          </div>
        </Col>
        <Col xs="12" sm="4">
          <div className={cx(Border.top4, Space.p5)}>
            <div style={{width:60,marginBottom:20}}>
              <Image filename="icon/r8_optimize.png" />
            </div>
            <h4 className={cx(Typo.bold, Typo.primary)}>Optimize</h4>
            <p>
              Iterate with confidence. Find out what works best simply and quickly, based on A.I. derived data that draws a clearer picture compared to plain open and click rates.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

// Double success, while doing less

export default Element