import React from 'react';
import {XYPlot, ArcSeries} from 'react-vis';
import { withSize } from "react-sizeme"

const DISCRETE_COLOR_RANGE = [
  '#0053d6',
  '#14234b',
  '#33e0c8',
  '#f0ebff',
  '#EF5D28'
];

const PI = Math.PI;

function getSeconds() {
  return Math.floor(new Date().getTime() / 1000);
}

function randomize () {
  return {
    o1: 60,
  }
}

class SunburstChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: props.size.width,
      time: 0
    }
  }

  componentDidMount() {
    this._timerId = setInterval(() => {
      this.setState({time: getSeconds()})
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this._timerId);
    this.setState({timerId: false});
  }

  render() {
    const {time} = this.state;
    const seconds = time % 60;
    const minutes = (time / 60) % 60;
    const hours = (time / (60 * 24)) % 24;
    return (
      <XYPlot
        xDomain={[-3, 3]}
        yDomain={[-3, 3]}
        width={this.state.width}
        getAngle={d => d.time}
        getAngle0={d => 0}
        height={300}
      >
        <ArcSeries
          animation={{
            damping: 9,
            stiffness: 300
          }}
          radiusDomain={[0, 3]}
          data={[
            {time: 0.6 * 2 * PI, radius0: 1, radius: 1.5, color: 0},
            {
              time: 0.4 * 2 * PI,
              radius0: 1.6,
              radius: 2.1,
              color: 1
            },
            {time: 0.8 * 2 * PI, radius0: 2.2, radius: 2.7, color: 2}
          ]}
          colorRange={DISCRETE_COLOR_RANGE}
        />
      </XYPlot>
    );
  }
}

export default withSize()(SunburstChart)