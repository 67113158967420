import React from "react"
import { cx } from "emotion"
import { Space, Flex, Typo, Grid, Speech, Btn } from "../../styles"
import { Row, Col } from "reactstrap"

import { Integrations } from "../../copy/integrations"
import Events from "../Elements/Events"
import Tasks from "../Elements/Tasks"
import Integration from "../Elements/Integration"

class Integs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIntegration: "",
      selectedCase: 0
    }
    this.selectCase = this.selectCase.bind(this)
  }

  selectCase (caseId) {
    this.setState({ selectedCase: caseId });
  }

  render () {
    return (
      <div className={cx(Space.pt4, Space.pb4)}>
        {/*<Row>
          <Col xs={4} className={cx(Space.p3)}>
            <button className={cx(Typo.small,Btn.reset,Btn.primaryBg,Space.mr2,Space.mb2)}>Example 1</button>
            <button className={cx(Typo.small,Btn.reset,Btn.primaryBg,Space.mr2,Space.mb2)}>Example 1</button>
          </Col>
        </Row>*/}
        <Row>
          <Col xs={12} sm={7}>
            <div className={cx(Speech.boxLeft)}>
              <div className={cx(Typo.small)} style={{opacity:0.7}}>EMAIL FROM LEAD</div>
              Hi Susan, you can reach me at 917 316 72 82. Looking forward to talking to you. Best, Michael
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={cx(Space.mr3,Space.ml3,Space.mt3)}>
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Automated Events</div>
          </Col>
          <Col xs={12} className={cx(Flex.hcl)}>
            <div className={cx(Space.mr3,Space.ml3)}>
              <Events.Event title="New Phone number" />
            </div>
            <div>
              <Events.Event title="Positive Response" color="green" bg="green" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={cx(Space.mr3,Space.ml3,Space.mt3)}>
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Automated Actions</div>
          </Col>
          <Col xs={12} sm={7}>
            <Tasks.Call text="Create task to call" />
          </Col>
        </Row>
      </div>
    )
  }
}

export default Integs;