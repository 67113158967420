import React from "react"
import { cx } from "emotion"
import {
  Space,
  Typo,
  Flex,
  Background
} from "../../styles"

import { 
  Analytics1,
  Analytics2,
  Flash
} from "../icons"

const Events = {
  WebsiteVisit: ({ text = "Website Visit", step = null, color }) => (
    <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)}>
      <div className={cx(Typo.red, Flex.hcc, Space.p1)}>
        <Analytics1 style={{width:18,height:18}} />
      </div>
      <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
        {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
        <div className={cx(Typo.small, Typo.muted)}>{text}</div>
      </div>
    </div>
  ),
  WebpageVisit: ({ text = "Website visit /product ", step = null, color}) => (
    <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)}>
      <div className={cx(Typo.orange, Flex.hcc, Space.p1)}>
        <Analytics1 style={{width:18,height:18}} />
      </div>
      <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
        {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
        <div className={cx(Typo.small, Typo.muted)}>{text}</div>
      </div>
    </div>
  ),
  WebsiteEvent: ({ text = "Website visit /product ", step = null, color}) => (
    <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)}>
      <div className={cx(Typo.green, Flex.hcc, Space.p1)}>
        <Analytics1 style={{width:18,height:18}} />
      </div>
      <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
        {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
        <div className={cx(Typo.small, Typo.muted)}>{text}</div>
      </div>
    </div>
  ),
  EmailOpen: ({ text = "Opened email", step = null, color}) => (
    <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)}>
      <div className={cx(Typo.primary, Flex.hcc, Space.p1)}>
        <Analytics2 style={{width:18,height:18}} />
      </div>
      <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
        {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
        <div className={cx(Typo.small, Typo.muted)}>{text}</div>
      </div>
    </div>
  ),
  EmailClick: ({ text = "Opened email", step = null, color}) => (
    <div className={cx(Flex.hcl,Space.p1, Background.white, Space.mb1)}>
      <div className={cx(Typo.standard, Flex.hcc, Space.p1)}>
        <Analytics2 style={{width:18,height:18}} />
      </div>
      <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
        {step && <div className={cx(Typo.small, Typo.bold)}>{`Step ${step}`}</div>}
        <div className={cx(Typo.small, Typo.muted)}>{text}</div>
      </div>
    </div>
  ),
}

export default Events;