import React from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  HorizontalGridLines
} from "react-vis";
import { withSize } from "react-sizeme"

const myDATA = [
  {y: 100, x: 5, fill: "#e1144b"},
  {y: 30, x: 7.5},
  {y: 15, x: 10},
  {y: 10, x: 12.5},
  {y: 8, x: 15},
  {y: 6, x: 17.5},
  {y: 5, x: 20},
  {y: 4, x: 22.5},
  {y: 3, x: 25},
  {y: 2, x: 27.5},
  {y: 1.5, x: 30},
  {y: 1, x: 32.5},
  {y: 1, x: 35},
  {y: 1, x: 37.5},
  {y: 1, x: 40}
];

const myDATA2 = [
  {y: 40, x: 5, fill: "blue", stroke: "blue"},
  {y: 10, x: 7.5},
  {y: 6, x: 10},
  {y: 3, x: 12.5},
  {y: 2, x: 15},
  {y: 1, x: 17.5},
  {y: 0, x: 20},
  {y: 0, x: 22.5},
  {y: 0, x: 25},
  {y: 0, x: 27.5},
  {y: 0, x: 30},
  {y: 0, x: 32.5},
  {y: 0, x: 35},
  {y: 0, x: 37.5},
  {y: 0, x: 40}
];

const yDomain = myDATA.reduce(
  (res, row) => {
    return {
      max: 100,
      min: 0
    };
  },
  {max: -Infinity, min: Infinity}
);

class ContactRate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      width: props.size.width,
      useCanvas: false
    }
  }

  render() {
    const {useCanvas,width} = this.state;
    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;
    return (
      <div>
        <XYPlot
          margin={{left: 40}}
          xType="time"
          width={width}
          height={300}
          yDomain={[yDomain.min, yDomain.max]}
          >
          <BarSeries className="vertical-bar-series-example" 
            data={myDATA} 
            barWidth={0.8} 
            fill="#123693"
            stroke="#123693" />
          <BarSeries className="vertical-bar-series-example" 
            data={myDATA2}
            barWidth={0.8}
            fill="#0053d6"
            stroke="#0053d6" />
          <XAxis 
            tickValues={[5, 15, 25, 35, 45, 55, 65, 75]}
            tickFormat={v => `${v}mins`}
            tickSizeInner={0}
            style={{
              line: {stroke: '#93a4d2'},
              text: {stroke: 'none', fill: '#93a4d2', fontWeight: 300, fontSize: 11}
            }} 
            />
          <HorizontalGridLines 
            tickValues={[20, 40, 60, 80]}
            style={{
              strokeDasharray: "1,3",
              stroke: "#93a4d2"
            }}
            />
          <YAxis 
            tickValues={[20, 40, 60, 80]}
            tickFormat={v => `${Math.round(v)}%`}
            style={{
              ticks: {stroke: 'none'},
              text: {stroke: 'none', fill: '#93a4d2', fontWeight: 300, fontSize: 11}
            }} 
            />
        </XYPlot>
      </div>
    );
  }
}

export default withSize()(ContactRate);