import React from "react"

export const Integrations = [{
  app: "salesforce/status",
  title: "Salesforce",
  category: "CRM",
  from: "integ/salesforcesq.png",
  to: "integ/deeploop2.png",
  text: "If lead in Salesforce has a status X, add the lead to Deeploop workflow Y",
  tags: ["leadmanagement"]
}, {
  app: "salesforce/campaign",
  title: "Salesforce",
  category: "CRM",
  from: "integ/salesforcesq.png",
  to: "integ/deeploop2.png",
  text: "If lead in Salesforce is part of campaign X, add the lead to Deeploop workflow Y",
  tags: ["leadmanagement"]
}, {
  app: "salesforce/outstatus",
  title: "Salesforce",
  category: "CRM",
  from: "integ/deeploop2.png",
  to: "integ/salesforcesq.png",
  text: (<span>If lead completes Deeploop workflow X <b>successfully</b>, move lead in Salesforce to status Y</span>),
  tags: ["outcome"]
}, {
  app: "salesforce/signature",
  title: "Salesforce",
  category: "CRM",
  from: "integ/deeploop2.png",
  to: "integ/salesforcesq.png",
  text: (<span>If lead email has signature, update lead attributes in Salesforce</span>),
  tags: ["outcome"]
}, {
  app: "calendly/new",
  title: "Calendly",
  category: "Events",
  from: "integ/calendly.png",
  to: "integ/deeploop2.png",
  text: `If lead has booked a meeting through Calendly. Remove lead from Deeploop workflow with status "success"`,
  tags: ["outcome",]
}];