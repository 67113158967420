import React from 'react'
import { cx } from 'emotion'
import { Space, Card, Typo, Grid, Layout, Links, Flex, Interactive, Background } from '../styles'
import Typos from "../components/Elements/Typo"
import { Row, Col } from "reactstrap"
import { CaretRight } from "../components/icons"
import { Penta, Octa } from "../components/shapes"

import Workflow from "../components/App/Workflow"
import Integrations from "../components/App/Integrations"
import Analytics from "../components/App/Analytics"
import Reporting from "../components/App/Reporting"
import Qualify from "../components/App/Qualify"

class FeatureClick extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpen: "workflow"
    }
    this.select = this.select.bind(this);
  }

  select (val) {
    this.setState({isOpen: val});
  }

  render () {
    return (
      <div className={cx(Space.section)} style={{overflow:"hidden !important"}}>
        <div className={cx(Typo.teal)} style={{position:"absolute",right:-60,top:100,width:300,height:300,transform:"rotate(270deg)"}}><Octa /></div>
        <Typos.SectionTitle 
          text={<span>What's included</span>} />
        <Row>
          <Col xs="12" sm="6" md="4">
            <div 
              onClick={() => this.select("workflow")}
              className={cx(Space.mb3, Links.tabSelect) + " " + (this.state.isOpen === "workflow" ? "active":"")}>
              <div className={cx(Flex.hcl,Typo.bold) + " title"}>Engagement and workflows<div className="rotate"><CaretRight style={{width:15,height:15,marginLeft:5}} /></div></div>
              <div className={cx(Typo.standard, Typo.reg) + " expand"}>
                <div className={cx(Space.mt2, Space.mb1)}>Send targeted emails, schedule calls, tasks on Linkedin. Program what happens when a workflow completes.</div>
              </div>
            </div>
            <div 
              onClick={() => this.select("data")}
              className={cx(Space.mb3, Links.tabSelect) + " " + (this.state.isOpen === "data" ? "active":"")}>
              <div className={cx(Typo.bold,Flex.hcl) + " title"}>Real-time customer data<div className="rotate"><CaretRight style={{width:15,height:15,marginLeft:5}} /></div></div>
              <div className={cx(Typo.standard, Typo.reg) + " expand"}>
                <div className={cx(Space.mt2, Space.mb1)}>Make website and email analytics data actionable to consistently engage leads at the right time.</div>
              </div>
            </div>
            <div 
              onClick={() => this.select("qualify")}
              className={cx(Space.mb3, Links.tabSelect) + " " + (this.state.isOpen === "qualify" ? "active":"")}>
              <div className={cx(Typo.bold,Flex.hcl) + " title"}>Qualify and convert
              <div className="rotate"><CaretRight style={{width:15,height:15,marginLeft:5}} /></div></div>
              <div className={cx(Typo.standard, Typo.reg) + " expand"}>
                <div className={cx(Space.mt2, Space.mb1)}>Use artificial intelligence to understand intent, extract data from lead replies and get suggestions on how to best respond to leads.</div>
              </div>
            </div>
            <div 
              onClick={() => this.select("integration")}
              className={cx(Space.mb3, Links.tabSelect) + " " + (this.state.isOpen === "integration" ? "active":"")}>
              <div className={cx(Typo.bold,Flex.hcl) + " title"}>Seamless integrations
              <div className="rotate"><CaretRight style={{width:15,height:15,marginLeft:5}} /></div></div>
              <div className={cx(Typo.standard, Typo.reg) + " expand"}>
                <div className={cx(Space.mt2, Space.mb1)}>True bi-directional CRM sync included and many more integrations that streamline and automate your sales processes.</div>
              </div>
            </div>
            <div 
              onClick={() => this.select("reporting")}
              className={cx(Space.mb3, Links.tabSelect) + " " + (this.state.isOpen === "reporting" ? "active":"")}>
              <div className={cx(Typo.bold, Flex.hcl) + " title"}>Reporting and Analytics
              <div className="rotate"><CaretRight style={{width:15,height:15,marginLeft:5}} /></div></div>
              <div className={cx(Typo.standard, Typo.reg) + " expand"}>
                <div className={cx(Space.mt2, Space.mb1)}>Gain better insights and understanding of your sales pipeline with real-time, enriched data. Quickly find out what works and how leads convert best.</div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="6" md="8" className={cx(Background.grey)} style={{height:"100%"}}>
            { this.state.isOpen === "workflow" ? (
              <div className={cx(Space.pl3, Space.pt4, Space.pb4, Space.pr3)}>
                <Workflow />
              </div>
            ):null}
            { this.state.isOpen === "integration" ? (
              <div className={cx(Space.pl3, Space.pt4, Space.pb4, Space.pr3)}>
                <Integrations />
              </div>
            ):null}
            { this.state.isOpen === "data" ? (
              <div className={cx(Space.pl3, Space.pt4, Space.pb4, Space.pr3)}>
                <Analytics />
              </div>
            ):null}
            { this.state.isOpen === "qualify" ? (
              <div className={cx(Space.pl3, Space.pt4, Space.pb4, Space.pr3)}>
                <Qualify />
              </div>
            ):null}
            { this.state.isOpen === "reporting" ? (
              <div className={cx(Space.pl3, Space.pt4, Space.pb4, Space.pr3)}>
                <Reporting />
              </div>
            ):null}
          </Col>
        </Row>
      </div>
    )
  }
}

export default FeatureClick;