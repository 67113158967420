import React from "react"
import Tasks from "../Elements/Tasks"
import { cx } from "emotion"
import { Space, Flex, Typo, Grid } from "../../styles"
import Events from "../Elements/Events"

import { ArrowRight } from "../icons"

class Workflow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  render () {
    return (
      <div className={cx(Grid.workflowGrid)}>
        <div className={cx(Space.pt4,Space.pb4)}>
          <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Inbound Workflow</div>
          <Tasks.Email text={"Automatic email"} />
          <Tasks.Email text={"Automatic followup email"} />
          <Tasks.Call text={"Call task"} />
          <Tasks.Draft text={"Email draft task"} />
          <Tasks.More text={"5 more items"} />
          <Tasks.Task text={"Find new lead at company"} />
        </div>
        <div className={cx(Flex.hcc)}>
          <div>
            <div className={cx(Typo.muted) + " text-center"}><ArrowRight style={{width:17}}/></div>
            <Events.Event title="Meeting booked" />
            <div className={cx(Typo.muted, Typo.small) + " text-center"}>Detected from Calendly</div>
          </div>
        </div>
        <div className={cx(Flex.hcc)}>
          <div style={{width:"100%"}}>
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Pre-Meeting Workflow</div>
            <Tasks.Linkedin text={"Connect on LinkedIn"} />
            <Tasks.Draft text={"Email draft task"} />
            <Tasks.Email text={"Automatic email"} />
            <Tasks.Email text={"Automatic email"} />
          </div>
        </div>
      </div>
    )
  }
}

export default Workflow;