import React from "react"
import { cx } from "emotion"
import Image from "../imageVar"
import { 
  Space, 
  Flex,
  Typo,
  Background
} from "../../styles"
import {
  Flash
} from "../icons"


const Events = {
  Event: ({ title = "", color = "primary", bg = "grey", sub = null }) => (
    <React.Fragment>
      <div className={cx(Flex.hcl, Space.pl1, Space.pr2, Space.pt1, Space.pb1, Background.white)} style={{borderRadius:100}}>
        <div className={cx(Typo[color], Flex.hcc, Space.p1, Background[bg])} style={{borderRadius:100}}>
          <Flash style={{width:16,height:16}} />
        </div>
        <div className={cx(Space.ml2)} style={{lineHeight:1.1}}>
          <div className={cx(Typo.small, Typo.muted)}>{title}</div>
        </div>
      </div>
      { !!sub && <div className={cx(Typo.muted, Typo.small, Space.mb1) + " text-center"}>{sub}</div>}
    </React.Fragment>
  )
}

export default Events;