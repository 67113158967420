import React from "react"
import { cx } from "emotion"
import { Space, Flex, Typo, Grid } from "../../styles"
import { Row, Col } from "reactstrap"

import { Integrations } from "../../copy/integrations"
import Events from "../Elements/Events"
import Tasks from "../Elements/Tasks"
import Integration from "../Elements/Integration"

import Sunburst from "../Charts/Sunburst"
import Timeday from "../Charts/Timeday"
import VBar from "../Charts/VerticalBars"

class Integs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIntegration: ""
    }
    this.selectIntegration = this.selectIntegration.bind(this)
  }

  selectIntegration (val) {
    this.setState({selectedIntegration: val});
  }

  render () {
    return (
      <div>
        <Row className={cx(Space.mb4,Space.pt4) + " text-center"}>
          <Col xs={12}>
            <div className={cx(Typo.cap, Typo.primary, Space.mb1)}>80+ REPORTS AND AB TESTING UTILITIES</div>
          </Col>
        </Row>
        <Row className={cx(Space.pb4,Space.pt2)}>
          <Col xs={12} sm={6} className="text-center">
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}><u>Qualified</u> Leads by Source</div>
            <Sunburst />
          </Col>
          <Col xs={12} sm={6} className="text-center">
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Inbound Leads Progression</div>
            <VBar />
          </Col>
        </Row>
      </div>
    )
  }
}

export default Integs;