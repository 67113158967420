import React from "react"
import { cx } from "emotion"
import { Space, Background, Typo, Layout, Card, Flex, Img, Btn, Transitions } from "../styles"
import Images from "../components/imageVar"
import { TestimonialsText } from "../copy/testimonials"
import { Dodec } from "../components/shapes"

// import { Onetick } from "../components/Image2"
import { ArrowLeft, ArrowRight } from "../components/icons"
import { Row, Col } from "reactstrap"

class Testimonial extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      shownId: 0,
      pause: false
    }
    this.switchPos = this.switchPos.bind(this);
    this.setPos = this.setPos.bind(this);
    this.intervals = this.intervals.bind(this);
  }

  componentDidMount () {
    this.intervals()
  }

  switchPos (change, active = false) {
    const current = this.state.shownId;
    let new_val = parseInt(current,10) + parseInt(change,10);
    if (new_val >= TestimonialsText.length) new_val = 0;
    if (new_val < 0) new_val = TestimonialsText.length - 1;
    this.setState({ shownId: new_val, pause: active });
  }

  setPos (position) {
    this.setState({ shownId: position, pause: true });
  }

  intervals () {
    setTimeout(() => {
      if (!this.state.pause) {
        this.switchPos(1, false);
        this.intervals();
      }
    }, 3500);
  }

  render () {
    const hl = TestimonialsText.find(el => el.id === this.state.shownId);

    return (
      <div className={cx(Space.section,Background.grey)}>
        <div className={cx(Typo.primary)} style={{position:"absolute",right:-60,top:100,width:300,height:300,transform:"rotate(270deg)"}}><Dodec /></div>
        <Row className={cx(Space.testimonialTitle, Typo.muted)}>
          <Col xs={12} sm={7}>
            <h1>Trusted by startups and <span className={cx(Typo.fancyu)}>large enterprise</span></h1>
          </Col>
        </Row>
        
        <div className={cx(Background.dark, Space.p6, Space.testimonialCardBody)} style={{position:'relative', borderRadius:2}}>
          <div style={{position:'absolute',bottom:0,right:0}} className={cx(Flex.hcc)}>
            <button onClick={() => this.switchPos(-1, true)} className={cx(Btn.reset, Btn.primary, Btn.icon)} style={{marginRight:3}}><ArrowLeft /></button>
            <button onClick={() => this.switchPos(1, true)} className={cx(Btn.reset, Btn.primary, Btn.icon)}><ArrowRight /></button>
          </div>
          <div 
            className={cx(Card.body, Layout.card33, Space.p4, Flex.vcc)} >
            { TestimonialsText.map((el, ix) => {
              const isShown = this.state.shownId === el.id;
              return (
                <div key={`x${ix}`} className={cx(Transitions.fadeInX, Space.pl5, Space.pr5) + " text-center " + (isShown ? "isShown":"")} style={{position:"absolute"}}>
                  <div style={{width:140,margin:'0px auto'}}><Images filename={el.logo} /></div>
                  <div className={cx(Typo.bold, Typo.muted)} style={{fontSize:100,lineHeight:'120px'}}>
                    {el.result}
                  </div>
                  <h4 className={cx(Typo.bold, Typo.muted, Space.mb0)}>
                    {el.result_sub}
                  </h4>
                </div>
              )
            })}
          </div>
          
          <Row>
            <Col xs={12} sm={7} style={{position:"relative"}}>
              {/* SPACING */}
              { hl && <div className={cx(Space.pl3, Space.pr3)} style={{opacity:0}}>
                <div className={cx(Typo.quote, Typo.huge, Space.mb4, Typo.bold)}>
                  {hl.text}
                </div>
                <div className={cx(Space.mb6, Flex.hcj)}>
                  <div>
                    {hl.person}<br />
                    {hl.title}
                  </div>
                </div>
              </div>}

              {/* MOVING */}
              { TestimonialsText.map((el, ix) => {
                const isShown = this.state.shownId === el.id;
                return (
                  <div key={`e${ix}`} 
                    className={cx(Transitions.fadeInX, Space.pl3, Space.pr3) + " " + (isShown ? "isShown":"")} 
                    style={{position:"absolute",top:0}}>
                    <div className={cx(Typo.quote, Typo.huge, Space.mb4, Typo.bold)}>
                      {el.text}
                    </div>
                    <div className={cx(Space.mb6)}>
                      {el.person}<br />
                      {el.title}
                    </div>
                  </div>
                )
              })}
            </Col>
          </Row>
        </div>

        <div className={cx(Space.p3,Space.mt4)}>
          <Row>
            {TestimonialsText.map((el,ix) => (
              <Col key={`l${ix}`} className={cx(Space.mb4) + " col-3 col-xs-6 col-sm-3"}>
                <div 
                  style={{maxWidth:"100%",width:150,margin:'0px auto'}} 
                  onClick={() => this.setPos(el.id)}
                  className={cx(Img.greyClick) + " " + (el.id === this.state.shownId ? "active" : "")}>
                  <Images filename={el.logo} />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
  }
}


export default Testimonial