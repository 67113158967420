import React from "react"
import { cx } from "emotion"
import { Space, Flex, Typo, Grid } from "../../styles"

import { Integrations } from "../../copy/integrations"
import Events from "../Elements/Events"
import Tasks from "../Elements/Tasks"
import Integration from "../Elements/Integration"

class Integs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIntegration: ""
    }
    this.selectIntegration = this.selectIntegration.bind(this)
  }

  selectIntegration (val) {
    this.setState({selectedIntegration: val});
  }

  render () {
    return (
      <div>
        { Integrations.map((el, ix) => (
          <Integration.fromTo app={el.app} />
        ))}
      </div>
    )
  }
}

export default Integs;