import React from "react"
import { Row, Col } from "reactstrap"

const Typo = {
  SectionTitle: ({ text, sub, style = {} }) => (
    <Row style={Object.assign({},{marginBottom:30},style)}>
      <Col xs="12" sm="7">
        <h1>{text}</h1>
        { sub && (<h4>{sub}</h4>)}
      </Col>
    </Row>
  )
}

export default Typo