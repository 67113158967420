import React from "react"
import { Row, Col } from "reactstrap"
import { cx } from "emotion"
import {
  Card,
  Flex,
  Space,
  Background,
  Typo
} from "../../styles"
import Image from "../imageVar"
import { CaretRight } from "../icons"
import {Integrations} from "../../copy/integrations"

const Integration = {
  fromTo: ({ app = "deeploop" }) => {
    const application = Integrations.find(el => el.app === app) || {};
    return (
      <div className={cx(Flex.hcl, Background.white, Space.mb2, Space.p2)}>
        <div className={cx(Flex.hcl, Space.mr3)}>
          <div style={{width:30,height:30}} className={cx(Space.mr2)}><Image filename={application.from} /></div>
          <div className={cx(Typo.muted)}>
            <CaretRight style={{width:15}}/>
          </div>
          <div style={{width:30,height:30}} className={cx(Space.ml2)}><Image filename={application.to} /></div>
        </div> 
        <div style={{lineHeight:1.2}} className={cx(Typo.med,Typo.muted,Space.pr3)}>{application.text}</div>
      </div>
    )
  }
}

export default Integration;