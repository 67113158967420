export const TestimonialsText = [{
  id: 0,
  text: "Our marketing works, it's systematically generating inbound leads. But converting the inbound to revenue was a blackbox with soft metrics. Deeploop boosted sales cycles and conversion rates from week 1.",
  person: "Matt Seann",
  title: "VP Sales, Sumo.com",
  result: "2x",
  result_sub: "Increase in inbound conversion rates",
  logo: "ref/sumo.png"
},{
  id: 1,
  text: "We run both inboud and outbound leads through Deeploop. Our inbound just went through the roof and we can simply achieve more with less work on the outbound side. Also we have much better measurements of what works.",
  person: "Max Muster",
  title: "VP of Sales, Digiday",
  result: "34%",
  result_sub: "Shorter sales cycles",
  logo: "ref/digiday.png"
},{
  id: 2,
  text: "We have leads trickling in from all directions. Webinars, conferences, website, referrals. Deeploop allowed us to streamline the engagement and follow up process, and eliminate the 40% and more of leakage we had.",
  person: "Ross Dubin",
  title: "VP of Sales, OneTick.com",
  result: "80%",
  result_sub: "Increase in new $ opportunities per month",
  logo: "ref/onetickn.png"
},{
  id: 3,
  text: "In Real Estate acting on incoming leads quickly is critical, since a lead usually keeps on browsing listing pages and quickly forgets or loses interest within a few minutes.",
  person: "Mitch Baylis",
  title: "VP Marketing, Home61",
  result: "1.5x",
  result_sub: "Increase in inbound conversion rates",
  logo: "ref/home61.png"
}]