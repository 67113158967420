import React from 'react';
import { cx } from "emotion";
import { Background, Typo } from "../../styles";
import chroma from "chroma-js"
import { Colors } from "../../styles/constants"


const data = [{
  leads: 100,
  label: 'Entered'
}, {
  leads: 96,
  label: 'Reached'
}, {
  leads: 81,
  label: 'Opened'
}, {
  leads: 70,
  label: 'Clicked'
}, {
  leads: 35,
  label: 'Replied Positive'
}, {
  leads: 21,
  label: 'Replied Negative'
}, {
  leads: 12,
  label: 'Out-of-Office'
},{
  leads: 10,
  label: 'Opted out'
},{
  leads: 8,
  label: 'Referred internally'
},{
  leads: 5,
  label: 'Changed company'
}]


const chromaScale = chroma.scale([Colors.primary.text, Colors.teal.text]);

class VerticalBars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <div>
        { data.map((el,ix) => {
          const this_color = chromaScale((ix + 1)/data.length).hex();
          return (
            <div style={{width:"100%",height:28,marginBottom:5,position:"relative"}} key={`x${ix}`}>
              <div style={{height:"100%",width: el.leads / 1.5 + "%",backgroundColor: this_color,borderRadius:2}}></div>
              <div style={{position:"absolute",right:0,top:3}} className={cx(Typo.muted,Typo.small)}>{el.label}</div>
            </div>
          )
        })}
      </div>
    );
  }
}

export default VerticalBars