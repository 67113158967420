// We think about performance all day.
// Don't take our word for it.

// This is what MIT says. This is what we measured.

import React from "react"
import { cx } from "emotion"
import { Space, Typo, Background, Flex } from "../styles"
import { Row, Col } from "reactstrap"

import { ArrowRight } from "../components/icons"
import { Cylinder } from "../components/shapes"

import ContactRate from "../components/Charts/ContactRate"
import FirstResponder from "../components/Charts/FirstResponder"

const AfterTestimonial = () => {
  return (
    <div className={cx(Space.section)} style={{position:"relative"}}>
      <div className={cx(Typo.purple)} style={{position:"absolute",left:-100,top:"70%",width:300,height:300,transform:"rotate(270deg)"}}><Cylinder /></div>
      <Row>
        <Col xs={12} sm={8} md={6}>
          <h1>Don't take our word for it</h1>
          <h4>We have studied inbound sales, so have others. It's fair to say the results are out.</h4>
        </Col>
      </Row>
      <Row className={cx(Space.mt4)} style={{overflow:"hidden"}}>
        <Col xs={12} sm={6}>
          <h4 className={cx(Typo.bold, Space.mb3)}>The customer expects <u>now</u></h4>
          <div className={cx(Background.grey, Space.p3, Space.mb4)} style={{position:'relative',paddingBottom:35}}>
            <ContactRate />
            <div className={cx(Typo.small, Typo.bold, Typo.orange, Flex.hcl)} style={{position:'absolute',bottom:10,left:40,paddingLeft:10,paddingBottom:10}}>
              <div>Longer to respond</div><ArrowRight style={{height:12,marginLeft:10}}/>
            </div>
            <div className={cx(Typo.tiny, Typo.muted)} style={{opacity:0.7, position:'absolute',bottom:0,right:0,paddingRight:10,paddingBottom:5}}>
              Source: MIT / Kellogg Study, Dr. Oldroyd
            </div>
            <div style={{lineHeight:1.2,position:'absolute',top:10,right:10}}>
              <div className={cx(Background.dark,Space.p2, Typo.small, Space.mb1)} style={{width:230,position:'relative'}}>
                <b>Contact Rate</b><br />
                100x decrease from 5 to 30mins
              </div>
              <div className={cx(Background.dark,Space.p2, Typo.small)} style={{width:230}}>
                <b>Qualification Rate</b><br />
                21x decrease from 5 to 30mins
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} style={{overflow:"hidden"}}>
          <h4 className={cx(Typo.bold, Space.mb3)}>The competition sells <u>now</u></h4>
          <div className={cx(Background.grey, Space.p3, Space.mb4)} style={{position:'relative',paddingBottom:35}}>
            <FirstResponder />
            <div className={cx(Typo.small, Typo.bold, Typo.orange, Flex.hcl)} style={{position:'absolute',bottom:10,left:40,paddingLeft:10,paddingBottom:10}}>
              <div>Win rate by competitor response time</div><ArrowRight style={{height:12,marginLeft:10}}/>
            </div>
            <div className={cx(Typo.tiny, Typo.muted)} style={{opacity:0.7, position:'absolute',bottom:0,right:0,paddingRight:10,paddingBottom:5}}>
              Source: Inside Sales Inc
            </div>
            <div style={{lineHeight:1.2,position:'absolute',top:10,right:10}}>
              <div className={cx(Background.dark,Space.p2, Typo.small, Space.mb1)} style={{width:230,position:'relative'}}>
                <b>First Company to respond</b><br />
                8x higher win-rate at 78%
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AfterTestimonial