import React from "react"
import Tasks from "../Elements/Tasks"
import { cx } from "emotion"
import { Space, Flex, Typo, Grid } from "../../styles"
import Events from "../Elements/Events"
import Analytics from "../Elements/Analytics"

import { ArrowRight } from "../icons"

class AnalyticsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  render () {
    return (
      <div className={cx(Grid.analyticsGrid)}>
        <div className={cx(Space.pt4, Space.pb4)}>
          <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Data sources</div>
          <Analytics.WebsiteVisit text={"Viewed website for 1:22 min"} />
          <Analytics.WebsiteEvent text={(<span>Did <pre>signup</pre> on website</span>)} />
          <Analytics.WebpageVisit text={(<span>Viewed <pre>/pricing</pre> for 0:31 min</span>)} />
          <Analytics.EmailOpen text={"Opened email"} />
          <Analytics.EmailClick text={"Clicked on link in email"} />
        </div>
        <div className={cx(Flex.hcc,Space.pl2,Space.pr2)}>
          <div>
            <div className={cx(Typo.muted) + " text-center"}><ArrowRight style={{width:17}}/></div>
            <Events.Event title="Real-Time Triggers" />
            <div className={cx(Typo.muted, Typo.small) + " text-center"}>3 seconds</div>
          </div>
        </div>
        <div className={cx(Flex.hcl)}>
          <div style={{width:"100%"}}>
            <div className={cx(Typo.cap, Typo.muted, Space.mb1)}>Real-time Actions</div>
            <Tasks.Call text={"Create real-time call"} />
            <Tasks.Draft text={"Email draft task"} />
          </div>
        </div>
      </div>
    )
  }
}

export default AnalyticsView;