import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  HorizontalGridLines
} from 'react-vis';
import { withSize } from "react-sizeme"

const myDATA = [
  {y: 0.78, x: 1, color: "#93a4d2"},
  {y: 0.12, x: 2},
  {y: 0.06, x: 3},
  {y: 0.02, x: 4},
];

const yDomain = myDATA.reduce(
  (res, row) => {
    return {
      max: 0.9,
      min: 0
    };
  },
  {max: -Infinity, min: Infinity}
);

class ContactRate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: props.size.width
    }
  }
  
  render() {
    return (
      <div>
        <XYPlot
          margin={{left: 40}}
          width={this.state.width}
          height={300}
          yDomain={[yDomain.min, yDomain.max]}>
          <VerticalBarSeries 
            data={myDATA}
            barWidth={0.5}
            fill="#123693"
            stroke="#123693" 
            />
          <XAxis 
            tickValues={[1, 2, 3, 4]}
            tickFormat={v => `Competitor ${v}`}
            tickSizeInner={0}
            style={{
              line: {stroke: '#93a4d2'},
              text: {stroke: 'none', fill: '#93a4d2', fontWeight: 300, fontSize: 10}
            }} 
            />
          <YAxis 
            tickValues={[0.2, 0.4, 0.6, 0.8]}
            tickFormat={v => `${Math.round(v * 100)}%`}
            style={{
              text: {stroke: 'none', fill: '#93a4d2', fontWeight: 300, fontSize: 10}
            }} 
            />
          <HorizontalGridLines 
            tickValues={[0.2, 0.4, 0.6, 0.8]}
            style={{
              strokeDasharray: "1,3",
              stroke: "#93a4d2"
            }}
            />
        </XYPlot>
      </div>
    );
  }
}

export default withSize()(ContactRate);