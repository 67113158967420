import React from "react"
import { cx } from "emotion"
import { Space, Typo, Background } from "../styles"
import Image from "../components/imageVar"
import { Row, Col } from "reactstrap"
import Typos from "../components/Elements/Typo"

// Customers Demand Attention Right-Now
// <Col xs="12" sm="4" className={cx(Space.pr4, Space.mb5)}>
//  <div style={{width:50,marginBottom:30}}>
//    <Image filename="icon/productivity.png" />
//  </div>
//  <h3 className={cx(Typo.bold, Space.mb2)}>Convert Marketing Spend</h3>
//  <p className={cx()}>Real-Time Sales converts your inbound leads up to 400% better.</p>
// </Col>

const Element = () => {
  return (
    <div className={cx(Space.section, Background.grey)}>
      <Typos.SectionTitle text={<span>Get started with the future of inbound</span>} style={{marginBottom:60}} />

      <Row className={cx(Space.mt4)}>
        <Col xs="12" sm="4" className={cx(Space.pr4, Space.mb5)}>
          <div style={{width:90,marginBottom:20}}>
            <Image filename="icon/r2_cdat.png" />
          </div>
          <h3 className={cx(Typo.bold, Space.mb2)}>Customer Data</h3>
          <p className={cx()}>Know what your leads do on your website, email and everywhere else.</p>
        </Col>
        <Col xs="12" sm="4" className={cx(Space.pr4, Space.mb5)}>
          <div style={{width:90,marginBottom:20}}>
            <Image filename="icon/r2_times.png" />
          </div>
          <h3 className={cx(Typo.bold, Space.mb2)}>Speed is the Edge</h3>
          <p className={cx()}>Get in front of your leads, the very moment you have their attention.</p>
        </Col>
        <Col xs="12" sm="4" className={cx(Space.pr4, Space.mb5)}>
          <div style={{width:90,marginBottom:20}}>
            <Image filename="icon/r2_wfs.png" />
          </div>
          <h3 className={cx(Typo.bold, Space.mb2)}>Automation</h3>
          <p className={cx()}>Seamlessly orchestrate leads to your team and to/from your existing software.</p>
        </Col>
      </Row>
    </div>
  )
}

export default Element