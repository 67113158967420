import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import HeroSection from "../sections/hero"


import ThreeFeatureSection from "../sections/threeFeatures"
import ClickFeatures from "../sections/featureClick"


import PreTestiSection from "../sections/preTestimonial"
import TestimonialSection from "../sections/testimonial"
import AfterTestimonial from "../sections/afterTestimonial"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <ThreeFeatureSection />
    <ClickFeatures />
    <PreTestiSection />
    <TestimonialSection />
    <AfterTestimonial />
  </Layout>
)

export default IndexPage
